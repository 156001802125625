@charset "UTF-8";

// Variables, functions common to styles.scss
// or needed in any other scss file

// Also ensures there's lots of DRY-ness going on

// !default is used to make customising colours much easier, like so:
// (contrived stylesheet)
//   $color: white;
//   @import "styles";

$header-background-image:      "/media/tri-bg.png";

// COLOURS
//
// A quick guide to variable names:
// haf: :hover, :active, :focus state
// top: stuff.ddlr header for custom pages
//   (i.e. pages with custom: true in yaml front matter)
$color:                        hsl(205deg,80%,66.6%) !default;

$color-dark:                   hsl(205deg,35%,8%) !default;
$color-light:                  #eee;



// TODO: this currently only supports dark mode. Transition below to support
// both light mode and dark mode

$color-background:             $color-dark !default;
$color-background-top:         lighten($color-dark, 10) !default;

$color-title-one:              $color !default;
$color-title-two:              darken($color, 30) !default;

$color-underline:              darken($color, 42.5) !default;
$color-underline-haf:          darken($color, 36) !default;
$color-underline-heading:      desaturate(darken($color-underline, 4), 30) !default;
$color-underline-haf-heading:  desaturate(darken($color-underline-haf, 6), 30) !default;

$color-background-discreet:    desaturate(darken($color, 52), 40) !default;
$color-background-footer:      desaturate(darken($color, 55), 30) !default;
$color-background-keyword:     $color-background-discreet !default;
$color-background-haf-keyword: lighten($color-background-discreet, 3) !default;

$color-secondary:              hsl(20deg,60%,33.3%) !default;

$color-arrow:                  $color-secondary !default;

$color-readmore:               lighten($color-secondary, 10) !default;
$color-underline-readmore:     darken($color-secondary, 12.5) !default;
$color-underline-haf-readmore: darken($color-secondary, 6) !default;

$color-secondary-dark:         darken($color-secondary, 20) !default;
$color-secondary-lighter:      lighten($color-secondary, 20) !default;

$color-body:                   $color-light !default;
// Unused
//$color-body-discreet:          darken($color-body, 20) !default;
$color-body-keyword:           darken($color-body, 20) !default;

// TYPEFACES
$type-heading:     "IBM Plex Sans", sans-serif !default;
$type-body:        "Source Serif 4", "Source Serif Pro", "Georgia Pro", "Georgia", serif !default;
$type-button:      "Gandhi Sans", sans-serif !default;

%italic {
  font-style: italic;
}

// MEASUREMENTS
$width: 40rem !default; // Must be in rem
$rem-multiplier: 1.25 !default; // 1rem = 1 * 1.25rem (1.25 * 16px = 20px)

// SIDEBAR MEASUREMENTS
// (the part where I (over)explain a lot of things so I don't forget)

// Calculates how close the triangle arrow (.article-title::before) is to the
// left border.
//
// 100vw - $width = entire viewport, excluding .box (main content)
// / 2            = since there's two equally sized columns to either side of .box
// - 4.15rem      = left property's value in .article-title::before
//                  i.e. how much arrow is pushed to the left by .article-title
$arrowvisible:                     "(100vw - #{$width}) / 2 - 4.15rem" !default;

// $arrowvisible, inverted.
// Gets the minimum viewport width where the arrow can be seen at all times,
// and have a little margin ($arrowvisible-margin) as well to ensure that
// $sidebar's minimum width is comfortable
//
// -mediaquery has $rem-multiplier added because "html { font-size: 1.5rem }"
// doesn't apply to media queries
$arrowvisible-margin:              3rem !default;
$arrowvisible-inverted:            ($arrowvisible-margin + 4.15rem) * 2 + $width !default;
$arrowvisible-inverted-mediaquery: (($arrowvisible-margin + 4.15rem) * 2 + $width) * $rem-multiplier !default;

// Minimum viewport width to show article sidebar
// It makes more sense if the arrow and sidebar appear as a cohesive unit,
// hence their breakpoints being the same
$sidebar:                          $arrowvisible-inverted !default;
$sidebar-mediaquery:               $arrowvisible-inverted-mediaquery !default;

// Determine sidebar width
// 100vw - $width = entire viewport, excluding .box (main content)
// / 2            = since there's two equally sized columns to either side of .box
// - 2rem         = space between sidebar and articles
// - 1rem         = margin of sidebar from left
$sidebar-width:                    '(100vw - #{$width}) / 2 - 2rem - 1rem' !default;

// value of left property on the (absolute-positioned) sidebar
// -1 *           = puts entire sidebar directly outside its parent
// - 2rem         = space between sidebar and articles
$sidebar-left:                     '-1 * (#{$sidebar-width}) - 2rem' !default;

// Screen width where sidebar stops growing
// Set at 8rem (arbitrary)
// Identical to the variables above, except with "+ 8rem" added
$sidebar-breakpoint:               #{$sidebar + 8rem} !default;
$sidebar-breakpoint-mediaquery:    #{$sidebar-mediaquery + 8rem * 1.5} !default;
// Above variables, adjusted for breakpoint
$sidebar-breakpoint-width:         '(#{$sidebar-breakpoint} - 30rem) / 2 - 2rem - 1rem' !default;
$sidebar-breakpoint-left:          '-1 * (#{$sidebar-breakpoint-width}) - 2rem' !default;

// MISC
$haf:        "hover", "active", "focus" !default;
$transition: cubic-bezier(.17,.84,.44,1) !default;

// append() inspired by
// http://hugogiraudel.com/2013/07/15/understanding-sass-lists/
//
// Returns a particular list of selectors, but as :hover, :active, and :focus.
// haf(("bacon", "egg")) becomes
// bacon:hover, bacon:active, bacon:focus, egg:hover, egg:active, egg:focus
//
// haf("bacon", ".egg") becomes
// bacon:hover.egg, bacon:active.egg, bacon:focus.egg
@function haf($selectors, $appendage: "") {
  $output: ();
  @each $item in $selectors {
    @each $haf-item in $haf {
      $output: append($output, $item + ":" + $haf-item + unquote($appendage), "comma");
    }
  }
  @return $output;
}

@mixin underline($underline-color) {
  // scss-lint:disable SpaceAfterPropertyColon
  text-decoration-color: #{$underline-color};
}
