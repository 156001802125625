//
// blog styling
// now with 100% more triangles!
//

@import "common";

:root {
  font-family: $type-body;
  font-size: clamp(1rem, calc(100vw / 30), #{$rem-multiplier}rem);
  color: $color-body;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "onum", "dlig";
  font-feature-settings: "onum", "dlig";
}

body {
  margin: 0;
  background-color: $color-background;
}

// Have content align to this so .top won't obscure content
.wrapper { position: relative; }

// Adds margins for small screens
.margins {
  padding-top: 2.75rem;
  padding-bottom: 2.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

// The box that encloses all the main content except footer
.box {
  max-width: $width;
  margin-right: auto;
  margin-left: auto;
}

// The margins to a generic section
// Use this class with .margins
.section-margins {
  margin-right: 0;
  margin-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $type-heading;
  font-weight: 600;
}

a {
  color: $color;
  text-decoration-skip-ink: all;
  text-underline-offset: .13em;
}

a,
#{haf(".title-link", " .title-no")} {
  // .title-no: parts of title with underline (i.e. all)
  @include underline($color-underline);
  background-position: 0 1.15em;
  background-repeat: repeat-x;
  background-size: .1em .1em;
}

#{haf("a")} { @include underline($color-underline-haf); }

em,
cite {
  @extend %italic;
}

.button {
  font-family: $type-button;
  border: 0;
}

//
// Sections of page
//

// Header background

.header-background {
  height: 10rem;
  max-height: 10vh;
  min-height: 2rem;
  background-attachment: fixed;
  background-image: url($header-background-image);
  background-size: cover;
}

// Title and page/site description

.header { margin-bottom: 2.4rem; }

.title {
  margin-top: .75rem;
  margin-bottom: .5rem;
  font-size: 2rem;
  letter-spacing: -0.5px;
}

.title-link {
  font-family: $type-heading;
  color: $color-title-one;
  text-shadow: none;
  background-image: none;
}

#{haf(".title-link")} { background-image: none; }

#{haf(".title-link", " .title-subdomain")} {
  @include underline($color);
}

.desc { margin-top: 0; }

// .top - stuff.ddlr header for custom pages

.top,
.top-hidden,
.top-title {
  transition: all .75s $transition;
}

.top {
  position: relative;
  height: 1.75rem;
  padding: .5rem;
  text-align: center;
  background-color: $color-background-top;
}

.top-hidden {
  height: 0;
  padding: 0;
  overflow: hidden;
}

.top-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.33rem;
}

.top-hidden > .top-title { font-size: 1.125rem; }

.top-button {
  position: absolute;
  top: 25%;
  right: 1rem;
  height: 50%;
  font-size: .875rem;
  color: $color-body;
  background-color: $color-background-top;
}

// Article title and content wrapper

.article { margin-top: 2.5rem; }

.article:first-of-type { margin-top: 0; }

.section-title {
  position: relative;
  margin-top: 0;
  margin-bottom: 1.25rem;
  font-size: 2rem;
  font-size: clamp(2rem, calc(100vw / 10), 2.75rem);
  line-height: 1.15;
}

.section-title-link {
  @include underline($color-underline);
  color: $color-body;
  background-position: 0 1.15em;
  background-size: .1em .085em;
}

#{haf(".section-title-link")} { @include underline($color-underline-haf); }

@media screen and (min-width: 720px) {
  .section-title-link { @include underline($color-underline-heading); }
  #{haf(".section-title-link")} {
    @include underline($color-underline-haf-heading);
  }
}

.article-content { position: relative; }

// Article sidebar

.article-side {
  font-size: .75rem;
  color: $color-body-keyword;
  opacity: .75;
  transition: opacity .25s $transition;
}

@media screen and (min-width: $sidebar-mediaquery) {
  .article-side {
    position: absolute;
    top: .35rem;
    left: calc(#{$sidebar-left});
    width: calc(#{$sidebar-width});
    text-align: right;
    opacity: .5;
  }
}

@media screen and (min-width: #{$sidebar-breakpoint-mediaquery}) {
  .article-side {
    left: calc(#{$sidebar-breakpoint-left});
    width: calc(#{$sidebar-breakpoint-width});
  }
}

@media not all and (hover) {
  .article-side { opacity: 1; }
}

#{haf(".article-side")} { opacity: 1; }

.article-date { margin-bottom: -.2rem; }

.article-side-inline { display: inline; }

.article-link { margin-right: .25rem; }

@media screen and (min-width: $sidebar-mediaquery) {
  .article-date { margin-bottom: 0; }
  .article-side-inline { display: block; }
  .article-link { margin-right: 0; }
}

.article-keywords {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.article-keyword-link {
  color: $color-body-keyword;
  background-image: none;
}

#{haf(".article-keyword-link")} {
  background-image: none;
}

.article-keyword {
  display: inline-block;
  padding-top: .1rem;
  padding-right: .2rem;
  padding-bottom: .02rem;
  padding-left: .2rem;
  margin-top: .2rem;
  margin-right: .1rem;
  line-height: 1.2;
  background-color: $color-background-keyword;
  border-radius: .25rem;
  transition: background .25s $transition;
}

#{haf(".article-keyword-link", " .article-keyword")} {
  background-color: $color-background-haf-keyword;
}

@media screen and (min-width: $sidebar-mediaquery) {
  .article-keyword { margin-right: 0; }
}

// Article content

.article-cat-story .article-side { top: .425rem; }

.article-content > :nth-child(2) { margin-top: 0; }

.article-content h1,
.article-content h2,
.article-content h3 {
  margin-top: 1.5em;
  margin-bottom: 0.4em;
}

.article {
  line-height: 1.5;
}

.article p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.article-cat-story p {
  margin-bottom: 0;
  text-align: justify;
  text-indent: 2rem;
}

.article p:first-of-type { text-indent: 0; }

.article-cat-story p:first-of-type::first-line {
  font-family: $type-heading;
  font-weight: 600;
  // Another Chrom* bug:
  // https://code.google.com/p/chromium/issues/detail?id=129669
  text-transform: lowercase;
  // Chromium can't render small caps with font-variant for some reason
  // Demo at https://www.cs.tut.fi/~jkorpela/small-caps.html
  // font-variant: small-caps;
  -webkit-font-feature-settings: "smcp";
  font-feature-settings: "smcp";
}

.readmore {
  @extend %italic;
  @include underline($color-underline-readmore);
  display: inline-block;
  // For mobile, where the post info goes under the .readmore element
  margin-bottom: 1rem;
  color: $color-readmore;
}

#{haf(".readmore")} { @include underline($color-underline-haf-readmore); }

img { max-width: 100%; }

pre { white-space: pre-wrap; }

blockquote {
  margin-left: 0;
  padding-left: 1.25rem;
  border-left: .2rem solid $color-dark;
}

%aside {
  border-left: #aaa solid .1rem;
  margin: 1rem 0;
  padding-left: 1rem;
}

// Use as <aside> element
.note {
  @extend %aside;
  font-size: .8rem;
}

// Title of note
// Use as <h3 class="note-heading">
.note-heading { font-size: 1rem; }

.example {
  @extend %aside;
}

.example p {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

// Footer

footer {
  padding: 1rem;
  font-size: .75rem;
  text-align: center;
  background-color: $color-background-footer;
}

footer a {
  @include underline($color-underline);
}

#{haf("footer a")} { @include underline($color-underline-haf); }

// Tables

table {
  margin: 2rem 0;
  width: 100%;
  font-size: .9em;
  // doesn't work unless we use "display: block", which
  // would make the "width: 100%" not work properly
  //
  // overflow-x: scroll;
  border-collapse: collapse;
  white-space: nowrap;
}

th,
td {
  border-bottom: $color solid .05rem;
  padding: .2rem .4rem;
}

